import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS
import './assets/css/styles.css';  // Your custom styles

// Ensure there is a root element in index.html with id 'root'
const rootElement = document.getElementById('root');

// Create a root and render the App
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
} else {
    console.error("Target container is not a DOM element");
}
