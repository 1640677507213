import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../../assets/css/Header.css'; // Ensure this path is correct

const Header = ({ user, setUser }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        setUser(null);
        navigate('/');
    };

    const handleDashboardClick = () => {
        if (user) {
            // Redirect to dashboard if user is logged in
            navigate('/dashboard'); // Replace '/dashboard' with your actual dashboard route
        } else {
            // Otherwise, go to landing page
            navigate('/');
        }
    };

    return (
        <Navbar className="custom-navbar" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/" onClick={handleDashboardClick} className="navbar-brand">
                    Adhyai
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {user && (
                            <>
                                <Nav.Link as={Link} to="/dashboard" onClick={handleDashboardClick} className="custom-nav-link">Dashboard</Nav.Link>
                                <Nav.Link as={Link} to="/statistics" className="custom-nav-link">Statistics</Nav.Link>
                            </>
                        )}
                    </Nav>
                    <Nav className="ms-auto">
                        {user ? (
                            <>
                                <Nav.Link disabled className="custom-nav-link">{user.email}</Nav.Link>
                                <Nav.Link onClick={handleLogout} className="custom-nav-link">Logout</Nav.Link>
                            </>
                        ) : (
                            <>
                                <Nav.Link as={Link} to="/login" className="custom-nav-link">Login</Nav.Link>
                                <Nav.Link as={Link} to="/signup" className="custom-nav-link">Sign Up</Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
