
import '../../assets/css/LandingPage.css';

import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import landingPageImage from '../../assets/images/landing_page_1.jpg'; // Adjust the path as needed


const LandingPage = () => {
    return (
        <div>
            <HeroSection />
            <FeaturesSection />
            <PersonalizationSection />
            <CommunitySection />
            <CallToActionSection />
        </div>
    );
};

const HeroSection = () => (
    <section className="hero-section">
        <Container>
            <Row className="align-items-center">
                <Col md={6}>
                    <h1 className="display-4 fw-bold mb-3">Empower Your Exam Success</h1>
                    <p className="lead text-muted">Personalized learning that adapts to you, ensuring you're always one step ahead.</p>
                    <Button variant="primary" as={Link} to="/signup" className="btn-lg">
                        Start Your Journey
                    </Button>
                </Col>
                <Col md={6}>
                    <Image src={landingPageImage} fluid rounded alt="landingpage Image" />
                    <p><a href="http://www.freepik.com">Designed by pch.vector / Freepik</a></p>
                </Col>
            </Row>
        </Container>
    </section>
);

const FeaturesSection = () => (
    <section className="features-section bg-light py-5">
        <Container>
            <h2 className="text-center mb-5 display-5">Your Path to Academic Excellence</h2>
            <Row className="g-4">
                <Col md={4}>
                    <Image src="https://via.placeholder.com/350x250" fluid rounded alt="Feature 1" />
                    <h3 className="mt-3">Adaptive Learning</h3>
                    <p>Tailored study plans that adjust to your progress and learning style.</p>
                </Col>
                <Col md={4}>
                    <Image src="https://via.placeholder.com/350x250" fluid rounded alt="Feature 2" />
                    <h3 className="mt-3">Practice & Review</h3>
                    <p>Unlimited practice tests, quizzes, and flashcards to reinforce your knowledge.</p>
                </Col>
                <Col md={4}>
                    <Image src="https://via.placeholder.com/350x250" fluid rounded alt="Feature 3" />
                    <h3 className="mt-3">Progress Tracking</h3>
                    <p>Track your performance, identify areas for improvement, and celebrate your achievements.</p>
                </Col>
            </Row>
        </Container>
    </section>
);

const PersonalizationSection = () => (
    <section className="personalization-section py-5">
        <Container>
            <Row className="align-items-center">
                <Col md={6}>
                    <Image src="https://via.placeholder.com/500x400" fluid rounded alt="Personalization" />
                </Col>
                <Col md={6}>
                    <h2 className="display-5 text-dark">Personalize Your Learning Journey</h2> {/* Added text-dark class */}
                    <p className="text-muted">
                        Take control of your education with our personalized learning tools. Identify your strengths and weaknesses, create a study plan tailored to your needs, and get personalized feedback to help you improve. 
                    </p>
                    <Button variant="primary" as={Link} to="/signup">
                        Learn More
                    </Button>
                </Col>
            </Row>
        </Container>
    </section>
);

const CommunitySection = () => (
    <section className="community-section py-5">
        <Container>
            <Row className="align-items-center">
                <Col md={6}>
                    <h2 className="display-5">Join a Supportive Community of Learners</h2>
                    <p>
                        Connect with students and mentors who share your passion for learning. 
                        Collaborate, ask questions, and find inspiration in our vibrant online community.
                    </p>
                    <Button variant="primary" as={Link} to="/community">
                        Join the Community
                    </Button>
                </Col>
                <Col md={6}>
                    <Image src="https://via.placeholder.com/500x400" fluid rounded alt="Community" />
                </Col>
            </Row>
        </Container>
    </section>
);


const CallToActionSection = () => (
    <section className="cta-section">
        <Container className="text-center py-5">
            <h2 className="display-5 fw-bold mb-3">Ready to Transform Your Learning?</h2>
            <p className="lead">
                Sign up today and discover a personalized learning experience designed to help you succeed.
            </p>
            <Button variant="light" as={Link} to="/signup" className="btn-lg">Sign Up Now</Button>
        </Container>
    </section>
);

export default LandingPage;
