import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { createUser } from './api';

const Signup = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const handleSignup = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        
        // Ensure variables are properly initialized
        let userDetails = {};
    
        if (email && password && name) {
            userDetails = {
                "FirstName": name,  // mandatory
                "UserId": email,    // mandatory
                "LastName": "",     // optional
                "ExamNames": [],    // optional
                "Password": password
            };
    
            setIsLoading(true); // Start loading

            try {
                // Await the createUser function
                const response = await createUser(userDetails);
                console.log("response------", response);
    
                if (response === true) {
                    navigate('/login'); // Navigate to the login page if user creation is successful
                } else {
                    setError('Please try again'); // Set error if response is not true
                }
            } catch (error) {
                // Handle any errors from createUser
                console.error('Error during user signup:', error.message || error);
                setError('An unexpected error occurred. Please try again.');
            } finally {
                setIsLoading(false); // Stop loading after API call completes
            }
        } else {
            setError('Please fill in all fields'); // Handle missing input fields
        }
    };
    
    return (
        <Container fluid className="p-5 bg-light">
            <Row className="justify-content-center">
                <Col md={6}>
                    <h2>Sign Up</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSignup}>
                        <Form.Group controlId="userName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span className="ms-2">Signing Up...</span>
                                </>
                            ) : (
                                'Sign Up'
                            )}
                        </Button>
                    </Form>
                    <p className="mt-3">
                        Already have an account? <Link to="/login">Login</Link>
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default Signup;
