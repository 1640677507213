import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Layout/Header';
import LandingPage from './components/Landing/LandingPage';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import Dashboard from './components/Dashboard/Dashboard';
import ExamTakingPage from './components/Dashboard/ExamTakingPage';

const AppRoutes = () => {
    const [user, setUser] = useState(null);

    return (
        <Router>
            <Header user={user} setUser={setUser} />
            <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login setUser={setUser} />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/dashboard" element={user ? <Dashboard user={user} /> : <LandingPage />} />
                <Route path="/exam/:testId" element={<ExamTakingPage user={user} />} />

            </Routes>
        </Router>
    );
};

export default AppRoutes;
