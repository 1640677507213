// src/components/Exam/api.js
import AWS from 'aws-sdk';
import { signApiRequest } from '../../config/awsConfig';

export const createUser = async (userDetails) => {
    const apiGateway = new AWS.APIGateway();
    console.log('Creating user with details:', userDetails);

    const params = {
        restApiId: 'ktifd916p1', // Replace with your actual RestApiId
        resourceId: 'szovfp12uk', // Replace with your actual ResourceId
        httpMethod: 'POST',
        body: JSON.stringify(userDetails),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        // If using a custom signing function
        const response = await signApiRequest(params);

        // Debug: Log raw response
        console.log('Raw response:', response);

        // Parse and return response data
        if (response.status === 200) {
            return true
        } else {
            return false
        }
    } catch (error) {
        console.error('Error creating user:', error.message || error);
        return false
    }
};


export const validateUser = async (email, password) => {
    const apiGateway = new AWS.APIGateway();
    const encodedEmail = encodeURIComponent(email); // URL-encode the email
    const pathWithQueryString = `/validateUser?userId=${encodedEmail}`;
    console.log("start validateUser", password);

    const params = {
        restApiId: 'kivgftjiei',
        resourceId: 'zue9ukvt6k',
        httpMethod: 'PUT',
        pathWithQueryString: pathWithQueryString,
        body: JSON.stringify(password), // Send password as JSON
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const response = await signApiRequest(params);
        
        // Log the raw response
        console.log('Raw response:', response);

        // Use the response directly if it's already an object
        const data = typeof response === 'string' ? JSON.parse(response) : response;

        console.log("Parsed response validateUser:", data);

        if (data.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error validating user:', error.message || error);
        return false;
    }
};



export default {createUser, validateUser};