import AWS from 'aws-sdk';

// Configure AWS SDK
AWS.config.update({
    accessKeyId: 'AKIAXYKJWXJ4B3NIUQAO',
    secretAccessKey: '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',
    region: 'us-east-2' 
});

// Function to sign API Gateway requests
export const signApiRequest = (params) => {
    const apiGateway = new AWS.APIGateway();
    return apiGateway.testInvokeMethod(params).promise(); // Returns a promise
};